// Hide the toolbar or admin menu on small screens.
@media all and (max-width: 739px) {
  #toolbar, #admin-menu {
    display: none;
  }

  html {
    body.toolbar, body.admin-menu {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
  }
}
